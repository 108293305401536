import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-blueammonia',
  templateUrl: './blueammonia.component.html',
  styleUrls: ['./blueammonia.component.scss']
})
export class BlueammoniaComponent implements OnInit {
  routerPage = '';
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const urlSegment = this.route.snapshot.url[0]; // Check if the first segment exists
    const category = urlSegment ? urlSegment.path : null; // Set category if path is available
  
    if (category) {
      console.log(category);
      switch (category) {
        case 'guest':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=606emKZfHbgbja1e76vv');
          break;
        case 'register/vip':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=VDDouM6NT3BqHkE62SK9');
          break;
        case 'rsvp/vvip':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=JR4JKvF1VY7tiaevdICo');
          break;
        case 'media':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=hvO3MEQ1RQFcIbexGh7D');
          break;
        case 'services':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=XrqV9GM2IclazQZfC6S4');
          break;
        case 'staff':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=dSV71rzg6IwiWnEcED15');
          break;
        case 'organizer':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=r3sNCL3MZhPkpjcMUqAq');
          break;
        case 'security':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=9NppJLgDv2FkV2UpmBNq');
          break;
        case 'driver':
          this.router.navigateByUrl('/form?formid=tBvLNknFmMyfumC2VKTp&categoryid=k5Al9CPa4vQfYGZdvqiT');
          break;
        default:
          this.router.navigateByUrl('/thank-you-pages/kVi643IlUm7QKWA22Ewd');
          break;
      }
    } else {
      // Redirect if no path is found
      console.log('No category found in the URL');
      this.router.navigateByUrl('/thank-you-pages/kVi643IlUm7QKWA22Ewd');
    }
  }
  

}
